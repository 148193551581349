import { useScreen } from '@/hooks/useScreen'
import { ActionIcon, Avatar, Box, Button, Collapse, Dialog, Divider, Text, UnstyledButton } from '@mantine/core'
import { IconChevronDown, IconClick, IconExternalLink, IconMouse, IconPlayerPlayFilled, IconX } from '@tabler/icons-react'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'

export default function DemoVideo() {
    const [demoVideoOpen, setDemoVideoOpen] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const { largeMobile } = useScreen()

    useEffect(() => {
        setTimeout(() => {
            setDemoVideoOpen(true)
        }, 3000);
    }, [])

    if (largeMobile) return null;
    return (
        <Dialog withBorder shadow='xl' p={0} position={{ bottom: 20, left: 20 }} opened={demoVideoOpen} onClose={() => setDemoVideoOpen(false)} style={{ overflow: "hidden" }}>
            <Box p="sm" py={7} className='flex cp' style={{ gap: 10 }} onClick={() => setExpanded(true)}>
                {/* <IconClick size={20} /> */}
                <Text>🍿</Text>
                <Box>
                    <Text fz="sm" fw={600}>Watch the demo</Text>
                    <Box className='flex aic' style={{ gap: 7 }}>
                        <Avatar size={"xs"} src={"/images/landing_page/kevin_irl.jpeg"} />
                        <Text fz="xs" c="dimmed">Kevin Jong, Founder</Text>
                    </Box>
                </Box>

                <Box ml={"auto"} className='flex aic jcc'>
                    {expanded
                        ? <ActionIcon onClick={() => setDemoVideoOpen(false)} variant='subtle'>
                            <IconX size={18} />
                        </ActionIcon>
                        : <ActionIcon onClick={() => setExpanded(false)} variant='subtle'>
                            <IconChevronDown size={18} />
                        </ActionIcon>
                    }

                </Box>
            </Box>
            <Collapse in={expanded}>
                <iframe
                    src="https://www.youtube.com/embed/WKjGzRYBxDI"
                    style={{
                        border: 0,
                        width: "100%",
                        display: "block"
                    }}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
                <Divider />
                <Box p="sm">
                    <Box className='flex aic' style={{ gap: 10 }}>
                        <Button component={Link} href="/pricing" style={{ flex: 1 }}>
                            Try for Free
                        </Button>
                        <Button component={Link} href={"https://meetings.hubspot.com/kevindejong/demo"} target='_blank' rightSection={<IconExternalLink size={18} />} style={{ flex: 1 }} variant='subtle'>Book a demo</Button>
                    </Box>
                </Box>
            </Collapse>
        </Dialog>
    )
}
