"use client"
import '@mantine/carousel/styles.css';
import Head from 'next/head'
import { Badge, Button, Divider, Paper, Rating, Text, Image as MantineImage, Box, ThemeIcon, Alert, Accordion, Image, alpha, Avatar, lighten, darken } from '@mantine/core'
import { ArrowRight, X, Link as LinkIcon, Users, Mouse, Lock, Paint, Check, Clock } from 'tabler-icons-react'
import Link from 'next/link'
import { Logo, MiniLogo } from '@/components/Logo'
import { bonusSellingPoints, mainSellingPoints, pullFactors, securityConcerns } from '@/static/landingPage'
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { DemoTabs } from '@/components/landing/featureTabs/FeatureTabs'
import { TypeAnimation } from 'react-type-animation'
import { useMediaQuery } from '@mantine/hooks'
import { AnyService } from '../../../types/global'
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import FinalCTA from '@/components/landing/finalCTA'
import { Carousel } from '@mantine/carousel';
import ActiveDemo from '@/components/landing/ActiveDemo';
import { useScreen } from '@/hooks/useScreen';
import FAQ from '@/components/landing/FAQ';
import IFrameResizer from "@iframe-resizer/react"
import { SupportedPlatforms } from '@/components/landing/SupportedPlatforms';
import WallOfLove from '@/components/landing/senja/WallOfLove';
import { IconHeart } from '@tabler/icons-react';
import ComponentBreakdown from '@/components/landing/ComponentBreakdown';
import SenjaReviews from '@/components/landing/senja/Reviews';
import Script from 'next/script';
import DemoVideo from '@/components/landing/DemoVideo';
const logoBar = [
    "Google Analytics",
    "Google Tag Manager",
    "HubSpot Partner",
    "Instagram",
    "Google Search Console",
    "Amazon Ads",
    "Twitter Ads",
    "Google Ads",
    "ProfitMetrics Partner",
    "Snapchat Ads",
    "YouTube Studio",
    "Shopify Store Collaborator"
] as Array<AnyService>

const Step = ({ vertical, image, step, title, content, inverted }: { vertical: boolean, image: React.ReactNode, step: number, title: string, content: any, inverted?: boolean }) => {
    return (
        <div className="flex jcsb" style={{
            flexDirection: vertical ? 'column-reverse' : inverted ? "row-reverse" : "row",
            gap: 20
        }}>
            {image}
            <div style={{ textAlign: inverted ? "left" : "left" }}>
                <Text c="dimmed" fw={800}>Step #{step}</Text>
                <Text mt={0} fz={25} fw={900}>{title}</Text>
                <div style={{ marginTop: 10 }}>{content}</div>
            </div>
        </div>
    )
}

const Testmonial = ({ image, name, role, review }: { image: string, name: string, role: string, review: string }) => {
    return (
        <Box className='flex jcc'>
            <Paper maw={600} w="100%" className='flex jcc fdc' mt={100} p="xl">
                <Text fw={500} fz={25} maw={500}><Text c="dimmed" fz={25} fw={900} span>❝ </Text>{review}<Text span c={"dimmed"} fw={900} fz={25}> ❞</Text></Text>
                <Divider w={100} my={20} />
                <Box className='flex aic' style={{ gap: 20 }}>
                    <Image src={image} style={{ borderRadius: 500 }} p={5} bg={"var(--mantine-color-blue-6)"} width={90} height={90} />
                    <Box>
                        <Text fz="xl" fw={500}>{name}</Text>
                        <Text c="dimmed" fw={500}>— {role}</Text>
                    </Box>
                </Box>
            </Paper>
        </Box>
    )
}

const IsThisTheWayYouOnboardYourClients = () => {
    const { reduceWidth, largerTablet, reduceTitleSize } = useScreen({
        reduceWidth: "875px",
        reduceTitleSize: "555px"
    })
    const Downsides = ({ downsides }: { downsides: string[] }) => {
        return (
            <Box mt={5} className='flex fdc' style={{ gap: 0 }}>
                {downsides.map((d, i) => (
                    <>
                        <Box bg={i % 2 ? "gray.0" : "#fff"} p={15} className='flex' style={{ gap: 10, borderRadius: 10 }}>
                            <X size={20} color='var(--mantine-color-red-6)' style={{ minWidth: 20 }} />
                            <Text fz={reduceWidth ? "sm" : undefined} fw={500}>{d}</Text>
                        </Box>
                    </>
                ))}
            </Box>
        )
    }
    return (
        <Box mx={20} className='flex aic jcc fdc'>
            <Badge variant='light' color='blue' size='lg' radius={5} style={{ borderLeft: "5px solid var(--mantine-color-blue-6)" }}>
                {reduceTitleSize ? "1 click client onboarding" : "Onboard clients in seconds with 1 click onboarding"}
            </Badge>
            <Text ta="center" mt={10} lh={reduceTitleSize ? 1.2 : undefined} fw={900} fz={reduceTitleSize ? 35 : 40}><span style={{
                background: `linear-gradient(25deg, rgba(0,0,0,.85) 55%, var(--mantine-color-blue-6) 100%)`,
                backgroundClip: "text",
                WebkitTextFillColor: "transparent"
            }}>Honestly,</span> is this the way to onboard your clients?</Text>
            <Box maw={largerTablet ? 450 : undefined} className={`flex ${largerTablet ? "fdc" : ""}`} mt={50} style={{ gap: 30 }}>
                <Box style={{ flex: 1, width: reduceWidth ? (largerTablet ? undefined : 300) : 400 }}>
                    <Paper shadow='xs' withBorder style={{ overflow: "hidden" }}>
                        <Image src={`/images/landing_page/manual.png`} />
                    </Paper>
                    <Box className='flex aic' style={{ gap: 10 }} mt={15}>
                        <Box bg={"gray.1"} style={{ borderRadius: 100 }} w={22} h={22} className='flex aic jcc'>
                            <Clock color='var(--mantine-color-gray-5)' size={18} />
                        </Box>
                        <Text fw={600} fz={reduceWidth ? "sm" : undefined}>Manual processes are slowing you down</Text>
                    </Box>
                    <Divider mt={15} opacity={.5} />
                    <Downsides downsides={[
                        "Constantly chasing clients for access details wastes time and delays projects.",
                        "Sharing passwords compromises security and creates unnecessary risks.",
                        "Creating onboarding guides or guiding clients through a call is a waste of time that could be better spent delivering results."
                    ]}
                    />
                </Box>
                <Box style={{ flex: 1, width: reduceWidth ? (largerTablet ? undefined : 300) : 400 }}>
                    <Paper shadow='xs' withBorder style={{ overflow: "hidden" }} className='flex aic jcc fdc'>
                        <Image src={`/images/landing_page/alternative.png`} />
                    </Paper>
                    <Box className='flex aic' style={{ gap: 10 }} mt={15}>
                        <Text fw={600} fz={reduceWidth ? "sm" : undefined}>Other tools don’t offer the flexibility you need</Text>
                    </Box>
                    <Divider mt={15} opacity={.5} />
                    <Downsides
                        downsides={[
                            "Overpriced and complicated platforms make onboarding a hassle.",
                            "Limited integrations mean you’re stuck with fewer options.",
                            "Lack of customization forces you to adapt to their process instead of your own."
                        ]}
                    />
                </Box>
            </Box>
        </Box>
    )
}

// const HowItWorks = () => {

//     const Section = ({ children }: PropsWithChildren) => {
//         return (
//             <Box style={{ height: "40vh" }}>
//                 <Box style={{ position: "sticky", top: 150 }}>
//                     {children}
//                 </Box>
//             </Box>
//         )
//     }

//     return (
//         <Box bg={"linear-gradient(0deg, #fff 0%, #fff 50%)"} px={"xl"}>
//             <Box pt={80} p="lg" maw={1100} mx="auto">
//                 <Badge variant='light' color='blue' size='lg' radius={5} style={{ borderLeft: "5px solid var(--mantine-color-blue-6)" }}>
//                     Get access in 3 easy steps
//                 </Badge>
//                 <Text fw={800} c={"#000"} fz={50}>How it works</Text>

//             </Box>
//             <Box mt={50} maw={750} mx="auto" className='flex' style={{ gap: 60 }}>
//                 <Box>
//                     <Box style={{ position: "relative", height: 2000 }}>
//                         <Box bg={"gray.2"} w={20} h={2000} style={{ position: "absolute", borderTopLeftRadius: 20, borderTopRightRadius: 20, }} />
//                         <Box h={100} w={20} bg={"var(--mantine-color-blue-6)"} style={{ position: "sticky", borderTopLeftRadius: 20, borderTopRightRadius: 20, boxShadow: "0px 00px 50px var(--mantine-color-blue-6)", top: 60 }} />
//                         <Box w={40} h={40} style={{ position: "sticky", top: 160 }}>
//                             <Box style={{ borderRadius: 100, position: "relative", left: -15, bottom: 5, boxShadow: "0px 00px 50px var(--mantine-color-blue-6)" }} bg={"blue.6"} w={50} h={50} className='flex aic jcc'>
//                                 <Box style={{ borderRadius: 100 }} w={20} h={20} bg="#fff" />
//                             </Box>
//                         </Box>
//                         <Box w={20} h={100} style={{ position: "sticky", top: 160 + 40 }} bg={"linear-gradient(0deg, var(--mantine-color-gray-2) 0%, var(--mantine-color-blue-6) 100%)"} />
//                         <Box style={{ position: "relative", top: 100, borderRadius: 100 }} bg={"transparent"} w={60} h={60}></Box>
//                     </Box>
//                 </Box>
//                 <Box style={{ flex: 1, gap: 100 }} p="xl" pt={90} className='flex fdc'>
//                     <Section>
//                         <Text fz={30} fw={800}><span style={{ color: "var(--mantine-color-dimmed)" }}>1.</span> Create an access link</Text>
//                         <Text mt={10} fw={500} lh={1.5} c="dimmed" fz="lg">Choose the services you want access to, and enter your client's infomation. We support <span style={{ color: "#fff" }}>Google, Meta, Shopify, and many more.</span></Text>
//                         <video muted autoPlay loop src="/images/landing_page/video/create_link.mp4" style={{ width: 450, marginTop: 20, borderRadius: 10 }}></video>
//                     </Section>
//                     <Section>
//                         <Text fz={30} fw={800}><span style={{ color: "var(--mantine-color-dimmed)" }}>2.</span> Share your link</Text>
//                         <Text mt={10} fw={500} lh={1.5} c="dimmed">Choose to send your link automatically via email, or manually via any other communication medium.</Text>
//                         <video muted autoPlay loop src="/images/landing_page/video/create_link.mp4" style={{ width: 450, marginTop: 20, borderRadius: 10 }}></video>
//                     </Section>
//                     <Section>
//                         <Text fz={30} fw={800}><span style={{ color: "var(--mantine-color-dimmed)" }}>3.</span> Your client grants access</Text>
//                         <Text mt={10} fw={500} lh={1.5} c="dimmed">Choose the services you want access to, and enter your client's infomation. We'll send your invite link to your client via email.</Text>
//                         <video muted autoPlay loop src="/images/landing_page/video/create_link.mp4" style={{ width: 450, marginTop: 20, borderRadius: 10 }}></video>
//                     </Section>
//                 </Box>

//             </Box>
//         </Box >
//     )
// }

const HowItWorks = () => {
    const { largeTablet, tablet } = useScreen()
    return (
        <Box px={20} pt={50} maw={1100} mx={"auto"}>
            <Badge variant='light' color='blue' size='lg' radius={5} style={{ borderLeft: "5px solid var(--mantine-color-blue-6)" }}>
                How it works
            </Badge>
            <Text mt={8} lh={1.1} fw={800} fz={tablet ? 35 : 40}>Start onboarding clients <br /> <span style={{ color: "var(--mantine-color-blue-7)" }}>in a few easy steps</span></Text>

            <Box mt={30} className={`flex ${tablet ? "fdc" : ""}`} style={{ gap: 10, flexWrap: "wrap" }}>
                {([
                    {
                        title: "Connect Accounts",
                        desc: "Link your agency's accounts from platforms like Facebook, Google Ads, and more to AgencyAccess in just a few clicks. No need for manual setup—get started right away.",
                        image: `/images/landing_page/steps/1.png`
                    },
                    {
                        title: "Customize Branding",
                        desc: `Personalize the onboarding experience with your logo, brand colors, and messaging. You can also customize the language of your client invites, ensuring a consistent and localized experience.`,
                        image: "/images/landing_page/steps/2.png"
                    },
                    {
                        title: "Invite Clients",
                        desc: `Send branded, easy-to-follow onboarding invitations to clients with one click. No more back-and-forth emails—everything is ready for your clients to start seamlessly.`,
                        image: "/images/landing_page/steps/3.png"
                    },
                    {
                        title: "Manage Access & Permissions",
                        desc: `Control and adjust your clients' account permissions from one central dashboard. Ensure you have the right access at the right time, with the flexibility to update or revoke permissions as needed.`,
                        image: "/images/landing_page/steps/4.png"
                    }
                ] as {
                    title: string,
                    desc: string,
                    image: string
                }[]).map((step, i) => {
                    return (
                        <Box w={tablet ? "100%" : (largeTablet ? "calc(100% / 2 - 10px)" : 270)} miw={tablet ? "100%" : (largeTablet ? "calc(100% / 2 - 10px)" : 250)} maw={tablet ? "100%" : (largeTablet ? "calc(100% / 2 - 10px)" : 270)} h="fit-content" bg={(i % 2 && !largeTablet) ? "gray.0" : "#fff"} py={"sm"} px={largeTablet ? 0 : "sm"} style={{ borderRadius: 10, flex: 1 }}>
                            <Paper maw={tablet ? 250 : undefined} withBorder style={{ aspectRatio: "16 / 9" }}>
                                <Image style={{ width: "100%", height: "100%", objectFit: "cover" }} src={`${step.image}`} alt={step.title} />
                            </Paper>
                            <Box mt={8}>
                                <Text fz={tablet ? 18 : 18} fw={600}>{step.title}</Text>
                                <Text fz={tablet ? 14 : "sm"} c="dimmed">{step.desc}</Text>
                            </Box>
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

const Reviews = () => {
    const { largeTablet, vertical, smallerHighlightText } = useScreen({
        vertical: "650px",
        smallerHighlightText: "530px"
    })
    return (
        <Box mt={80} mb={50} mx={10} style={{ maxWidth: "100vw" }} className='flex aic jcc fdc'>
            <SupportedPlatforms />
            <Text
                maw={800}
                mt={20}
                fw={900}
                fz={vertical ? (smallerHighlightText ? 32 : 35) : 40}
                lh={vertical ? 1.2 : undefined}
                ta={"center"}

            >
                <span style={{
                    color: "#fff",
                    padding: 5,
                    borderRadius: 5,
                    // textShadow: "inset 0px 2px 1px rgba(0,0,0,.25)",
                    background: `linear-gradient(0deg, rgba(0,0,0,1) 0%, var(--mantine-color-blue-6) 100%)`,
                    boxShadow: "0px 5px 5px rgba(0,0,0,.2)",
                    fontSize: smallerHighlightText ? 30 : "inherit",
                    WebkitLineClamp: 1,
                    textOverflow: "ellipsis",
                    overflow: "hidden",

                    // backgroundClip: "text",
                    // WebkitTextFillColor: "transparent"
                }}>500+ <TypeAnimation
                        preRenderFirstString
                        sequence={[
                            'SEO Agencies', 5000,
                            "PPC Agencies", 5000,
                            'Marketing Agencies', 5000
                        ]}
                        repeat={Infinity}
                    /></span><br /> <span style={{
                        textShadow: "inset 0px 2px 1px rgba(0,0,0,.25)",
                        background: `radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(0,0,0,.7) 100%)`,
                        backgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        position: 'relative',
                        top: vertical ? 8 : 0

                    }}>Have already fixed their onboarding with AgencyAccess</span>
            </Text>
            <Box w={"100%"} className={`flex  jcc ${vertical ? "fdc" : ""}`} mt={vertical ? 28 : 20} style={{ gap: 20 }}>
                {[
                    {
                        content: "AgencyAccess is such a handy tool. It’s made getting access to client accounts so much easier. Just send them a link, and that’s it. No more endless emails or chasing people for access. Saves a ton of time and hassle.",
                        name: "Tom van bodegraven",
                        position: "Teamlead SEA @ Endeavour",
                        image: "/images/landing_page/tom.jpeg"
                    },
                    {
                        content: "AgencyAccess made our onboarding faster and easier. Saves us hours a week, and our clients love it!",
                        name: "Ruben Runneboom",
                        position: "Co-founder at Taskforce Agency",
                        image: "/images/landing_page/ruben.jpeg"
                    },
                    // {
                    //     content: "AgencyAccess made our onboarding faster and easier. Saves us hours a week, and our clients love it!",
                    //     name: "Stefan Ionita",
                    //     position: "Dagami Marketing",
                    //     image: "/images/landing_page/kevin.jpeg"
                    // }
                ].map((test) => (
                    <Paper py={5} withBorder shadow='sm' style={{ flex: 1, maxWidth: 500, overflow: "hidden" }} className='flex'>
                        <Box p="sm">
                            <Image src={test.image} w={60} h={60} style={{ borderRadius: 100 }} />
                        </Box>
                        {/* <Box h={180} miw={125} style={{
                                            backgroundImage: `url(${test.image})`,
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                            backgroundRepeat: "no-repeat",
                                            flex: 1
                                        }}></Box> */}
                        <Box p="sm" className="flex fdc">
                            <Box>
                                <Rating value={5} readOnly />
                                <Text mt={4} fz="sm">{test.content}</Text>
                            </Box>
                            <Box style={{}}>
                                <Text mt={5} fz="sm" fw={600}>{test.name}</Text>
                                <Text lh={1} fz="xs" c="dimmed">{test.position}</Text>
                            </Box>
                        </Box>
                    </Paper>
                ))}
            </Box>

            {/* 
                             <Testmonial
                                    image='images/landing_page/kevin.webp'
                                    name='Stefan Ionita'
                                    review='AgencyAccess made our onboarding faster and easier. Saves us hours a week, and our clients love it!'
                                    role='Dagami Marketing'
                                />

                            */}
        </Box>
    )
}

const MainCTA = () => {
    const { smallerDesktop, smallHeader, stackHeaderText } = useScreen({
        smallHeader: "555px",
        stackHeaderText: "515px"
    })
    return (
        <div className='flex aic jcc' style={{
            width: "100%", position: "relative",
            background: "var(--mantine-color-dark-9)",
            minHeight: 700,
            zIndex: 0,
            paddingTop: 40,
            overflow: "hidden"
        }}>
            <div
                style={{
                    background: `radial-gradient(circle, ${"var(--mantine-color-blue-5)"} 0%, rgba(0,0,0,1) 45%)`,
                    position: "absolute",
                    width: "300%",
                    height: "300%",
                    top: -10,
                    zIndex: 10
                }}
            />
            <Box mx={20} style={{ zIndex: 10, marginBottom: 60 }} className="flex aic fdc">
                <Badge variant='light' color='blue' size='lg' radius={5} style={{ borderLeft: "5px solid var(--mantine-color-blue-6)" }}>
                    {smallHeader ? "1 click client onboarding" : "Onboard clients in seconds with 1 click onboarding"}
                </Badge>
                <Text maw={1000} mt={5} lh={1.3} fw={900} fz={smallerDesktop ? 36 : 48} style={{
                    textShadow: "inset 0px 2px 1px rgba(0,0,0,.25)",
                    background: "#fff",
                    // background: `linear-gradient(25deg, rgba(255,255,255,.85) 60%, ${lighten("var(--mantine-color-blue-6)", .35)} 100%)`,
                    backgroundClip: "text",
                    WebkitTextFillColor: "transparent"
                }} ta="center">
                    Simplify Client Onboarding for {!smallHeader || stackHeaderText ? <br /> : ""} <span style={{
                        lineHeight: 1,
                        WebkitTextFillColor: "transparent",
                        padding: 5,
                        borderRadius: 10,
                        textShadow: "0px 2px 20px rgba(255,255,255,.25)",
                        background: `linear-gradient(75deg, var(--mantine-color-blue-3) 0%, #fff 100%)`,
                        backgroundClip: "text",
                        fontWeight: 10000
                    }}><TypeAnimation
                            preRenderFirstString
                            sequence={[
                                'SEO Agencies', 5000,
                                "PPC Agencies", 5000,
                                'Marketing Agencies', 5000
                            ]}
                            repeat={Infinity}
                        /></span>
                </Text>
                <Text mt={15} fz={smallerDesktop ? (smallHeader ? 15 : "md") : "lg"} ta={"center"} c="rgba(255,255,255,.65)" maw={800} fw={600}>
                    Forget the hassle of sharing passwords or guiding clients through complicated steps. <span >With AgencyAccess, you can connect to all your clients' accounts by sending just one link—making client onboarding effortless and secure.</span>
                </Text>
                {/* <SenjaReviews mt={20} darkmode /> */}
                {/* <div className="senja-embed" data-id="6d32a22f-9782-4122-9ca4-4901fbd3e452" data-mode="shadow" data-lazyload="false" style={{ display: "block" }}></div> */}
                <SenjaReviews darkmode mt={15} />
                {/* <Image mt={20} alt='Rated 5 out of 5 (source Senja)' src={"/images/landing_page/senja/inline-dark.png"} style={{ height: 40, objectFit: "contain", width: "fit-content" }} /> */}
                {/* <iframe src="https://widget.senja.io/widget/6d32a22f-9782-4122-9ca4-4901fbd3e452" frameBorder={0}></iframe> */}
                <div style={{ marginTop: 30 }} className="flex fdc aic">
                    <Box mt={0} className='flex aic' style={{ gap: 15 }}>
                        <div className='borderGlow'>
                            <div className='inner'>
                                <Link href={"/pricing"}>
                                    <Button style={{}} radius={15} rightSection={<ArrowRight size={20} />} size="xl">
                                        <Box className='flex fdc aic'>
                                            <Text lh={1} fz={"lg"} fw={600}>
                                                Start for free today
                                            </Text>
                                            <Text c="dimmed" lh={1} mt={4} fz="xs">No CC Required</Text>
                                        </Box>
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Box>
                    <Text mt={7} ta="center" c="dimmed" fz="sm">or <Text component={Link} href={"https://meetings.hubspot.com/kevindejong/demo"} span fz="sm" c={"#fff"} style={{ textDecoration: "underline" }}>book a demo</Text></Text>
                </div>
            </Box>
        </div>
    )
}

export default function Home() {
    const vertical = useMediaQuery("(max-width: 768px)")
    const { activeDemoMobile, tablet, mediumTablet } = useScreen({
        activeDemoMobile: "865px"
    })

    return (
        <>
            <Head>
                <Script strategy='beforeInteractive' id="cookieyes" type="text/javascript" defer src="https://cdn-cookieyes.com/client_data/297a04d6268f2b8095ba3f28/script.js"></Script>
                {/* @ts-ignore */}
                <title>AgencyAccess | Credentials Made Simple</title>
                <meta name="facebook-domain-verification" content="xdzr3jk0mgeiw2rdsxe3gxclw6k3sw" />
                <meta name="description" content="Collect your client credentials using our simple, easy to manage platform" />
                {/* @ts-ignore */}
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <link rel="icon" href="/favicon.ico" />

                <meta name="title" content="AgencyAccess | Credentials Made Simple" />
                <meta name="description" content="Collect your client credentials using our simple, easy to manage platform" />
                <meta name="theme-color" content="#488ffe" />
                <meta property="og:locale" content="en_GB" />

                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.agencyaccess.co/" />
                <meta property="og:title" content="AgencyAccess | Credentials Made Simple" />
                <meta property="og:description" content="Collect your client credentials using our simple, easy to manage platform" />
                <meta property="og:image" content="https://storage.googleapis.com/agencyaccess-branding-assets/meta_images/aa_og_image.png" />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content="https://www.agencyaccess.co/" />
                <meta property="twitter:title" content="AgencyAccess | Credentials Made Simple" />
                <meta property="twitter:description" content="Collect your client credentials using our simple, easy to manage platform" />
                <meta property="twitter:image" content="https://storage.googleapis.com/agencyaccess-branding-assets/meta_images/aa_og_image.png" />

            </Head>
            <div style={{ padding: '0 0px' }}>
                <DemoVideo />
                <MainCTA />
                {!activeDemoMobile
                    ? <ActiveDemo />
                    : <Box style={{ position: 'relative', bottom: 60 }}>
                        <ComponentBreakdown />
                    </Box>
                }

                <div style={{ width: "100%", padding: `${activeDemoMobile ? 0 : 50}px 0` }} className="flex jcc">
                    <div style={{ width: "100%", maxWidth: 1100, padding: 10 }}>
                        <div style={{ margin: "0px 15px", gap: vertical ? 40 : 20 }} className={`flex jcsb ${vertical ? 'fdc' : ''}`}>
                            {bonusSellingPoints.map((bsp, i) => {
                                return (
                                    <>
                                        {!!i ? <Divider h={vertical ? undefined : 150} orientation={vertical ? "horizontal" : 'vertical'} opacity={.3} /> : null}

                                        <Box style={{ flex: 1, justifyContent: "space-between" }} className={`flex fdc ${vertical ? "aic" : ""}`}>
                                            <div className={`flex fdc ${vertical ? "aic" : ""}`} style={{ gap: 10, flex: 1 }}>
                                                <Paper bg={"linear-gradient(0deg, rgba(34,139,230,.05) 0%, rgba(255,255,255,1) 100%)"} shadow='md' w={vertical ? 70 : 80} h={vertical ? 70 : 80} style={{ borderRadius: 100 }} className='flex aic jcc'>
                                                    <Image src={`${bsp.image}`} w={50} />
                                                </Paper>
                                                <Text ta={vertical ? "center" : "left"} fz={25} fw={800}>{bsp.title}</Text>
                                                <Text ta={vertical ? "center" : "left"} c="dimmed">{bsp.body}</Text>
                                            </div>

                                        </Box>
                                    </>
                                )
                            })}
                        </div>
                        <Box className='flex aic jcc' mt={50}>
                            <Link href={"/pricing"}>
                                <Button style={{}} radius={15} rightSection={<ArrowRight size={20} />} size="xl">
                                    <Box className='flex fdc aic'>
                                        <Text lh={1} fz={"lg"} fw={600}>
                                            Start for free today
                                        </Text>
                                        <Text c="dimmed" lh={1} mt={4} fz="xs">No CC Required</Text>
                                    </Box>
                                </Button>
                            </Link>

                        </Box>
                        <Reviews />
                    </div>
                </div>
                <Box className='flex aic jcc fdc' style={{ width: "100vw", maxWidth: 1100, }} pb={80} mx={"auto"}>
                    <IsThisTheWayYouOnboardYourClients />
                </Box>

                <HowItWorks />

                <div style={{ width: "100%", padding: "50px 0", background: "#fff" }} className="flex jcc">
                    <div style={{ width: "100%", padding: vertical ? 0 : 10 }}>
                        {!mediumTablet
                            ? <Box mt={50} maw={1000} w="100%" mx="auto" className='flex aic fdc'>
                                <Badge variant='light' color='blue' size='lg' radius={5} style={{ borderLeft: "5px solid var(--mantine-color-blue-6)" }}>
                                    Features
                                </Badge>
                                <Text mb={40} mt={8} lh={1.1} fw={800} fz={tablet ? 35 : 40}>Explore our <span style={{ color: "var(--mantine-color-blue-7)" }}>featureset</span></Text>
                                <ComponentBreakdown />
                            </Box>
                            : <>
                                <Box className='flex aic fdc' mb={25}>
                                    <Text ta="center" lh={1.1} fw={800} fz={tablet ? 35 : 40} style={{ position: "relative" }}>

                                        <span style={{
                                            lineHeight: 1,
                                            textShadow: `0px 2px 10px ${alpha("var(--mantine-color-red-6)", .2)}`,
                                            background: `linear-gradient(90deg, var(--mantine-color-red-6) 0%, var(--mantine-color-red-5) 100%)`,
                                            backgroundClip: "text",
                                            WebkitTextFillColor: "transparent",
                                            position: 'relative',
                                            // top: vertical ? 8 : 0
                                        }}>
                                            <IconHeart fill='var(--mantine-color-red-6)' color='var(--mantine-color-red-6)' size={20} style={{ position: "absolute", top: -5, left: -15, transform: "rotate(-25deg)" }} />
                                            <IconHeart fill='var(--mantine-color-red-6)' color='var(--mantine-color-red-6)' size={20} style={{ position: "absolute", top: -8, left: 8, transform: "rotate(20deg)" }} />
                                            <IconHeart fill='var(--mantine-color-red-6)' color='var(--mantine-color-red-6)' size={20} style={{ position: "absolute", top: 20, left: -22, transform: "rotate(-40deg)" }} />
                                            Loved</span> by over 500 agencies
                                    </Text>
                                </Box>
                                <WallOfLove />
                            </>
                        }

                        {/* <iframe frameBorder={0} id='wall-of-love-embed' style={{ width: "100%", height: 600, overflow: "hidden", marginTop: 30 }} src="https://widget.senja.io/widget/1f1d4a17-60c0-4f54-b580-86cc5ade8062"></iframe> */}

                        <div style={{ width: "100%", gap: 50, marginBottom: 60 }} className={`flex aic jcc ${vertical ? 'fdc' : ''}`}>
                            {/* <Skeleton style={{ height: 100 }} /> */}
                            {/* <Image alt="Dagami logo" src={"/images/landing_page/companies/Dagami.png"} w={100} h={100} style={{ objectFit: "contain", filter: "grayscale(100%)" }} /> */}
                            {/* <Skeleton style={{ height: 100 }} /> */}
                            {/* <Image alt="Dagami logo" src={"/images/landing_page/companies/growyourecom.png"} w={160} h={100} style={{ objectFit: "contain", filter: "grayscale(100%) invert(1)", }} /> */}
                            {/* <Image alt="Taskforce Agency logo" src={"/images/landing_page/companies/taskforce.png"} w={160} h={100} style={{ objectFit: "contain", filter: "grayscale(100%) invert(0)", }} /> */}
                            {/* <Skeleton style={{ height: 100 }} /> */}
                        </div>
                        <Box mx={20}>
                            <FAQ />
                        </Box>
                        {/* <FinalCTA /> */}
                    </div>

                </div>
            </div >
        </>
    )
}
